import * as React from "react";
import Navside from "./Navside.jsx";
import AboutNav from "./AboutNav.tsx";
import ProductNav from "./ProductNav.tsx";
import SolutionsNav from "./SolutionsNav.tsx";
import ResourcesNav from "./ResourcesNav.tsx";

import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

const components: { title: string; href: string; description: string }[] = [
  {
    title: "Alert Dialog",
    href: "/docs/primitives/alert-dialog",
    description:
      "A modal dialog that interrupts the user with important content and expects a response.",
  },
  {
    title: "Hover Card",
    href: "/docs/primitives/hover-card",
    description:
      "For sighted users to preview content available behind a link.",
  },
  {
    title: "Progress",
    href: "/docs/primitives/progress",
    description:
      "Displays an indicator showing the completion progress of a task, typically displayed as a progress bar.",
  },
  {
    title: "Scroll-area",
    href: "/docs/primitives/scroll-area",
    description: "Visually or semantically separates content.",
  },
  {
    title: "Tabs",
    href: "/docs/primitives/tabs",
    description:
      "A set of layered sections of content—known as tab panels—that are displayed one at a time.",
  },
  {
    title: "Tooltip",
    href: "/docs/primitives/tooltip",
    description:
      "A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.",
  },
];

export default ({ data }) => {
  return (
    <NavigationMenu className="px-4">
      <NavigationMenuList className="mb-0">
        <NavigationMenuItem className="border-transparent border-b-[3px] hover:border-seablue">
          <NavigationMenuTrigger
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            Product
          </NavigationMenuTrigger>
          <NavigationMenuContent
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            <div className="w-screen border-b border-gray-200 bg-gradient-to-r from-white to-gray-50 from-75% to-70%">
              <div className="container">
                <div className="pt-10 pb-14 pr-5">
                  <ProductNav data={data} />
                </div>
                <Navside data={data} />
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="border-transparent border-b-[3px] hover:border-seablue">
          <NavigationMenuTrigger
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            Solutions
          </NavigationMenuTrigger>
          <NavigationMenuContent
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
            className="navbg-white-gray"
          >
            <div className="w-screen border-b border-gray-200 bg-gradient-to-r from-white to-gray-50 from-75% to-70%">
              <div className="container">
                <div className="pt-10 pb-14 pr-5">
                  <SolutionsNav data={data} />
                </div>
                <Navside data={data} />
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="border-transparent border-b-[3px] hover:border-seablue">
          <NavigationMenuTrigger
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            Resources
          </NavigationMenuTrigger>
          <NavigationMenuContent
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
            className="navbg-white-gray"
          >
            <div className="w-screen border-b border-gray-200 bg-gradient-to-r from-white to-gray-50 from-75% to-70%">
              <div className="container">
                <div className="pt-10 pb-14 pr-5">
                  <ResourcesNav data={data} />
                </div>
                <Navside data={data} />
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="border-transparent border-b-[3px] hover:border-seablue">
          <NavigationMenuTrigger
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
          >
            About
          </NavigationMenuTrigger>
          <NavigationMenuContent
            onPointerMove={(event) => event.preventDefault()}
            onPointerLeave={(event) => event.preventDefault()}
            className="navbg-white-gray"
          >
            <div className="w-screen border-b border-gray-200 bg-gradient-to-r from-white to-gray-50 from-75% to-70%">
              <div className="container">
                <div className="pt-10 pb-14 pr-5">
                  <AboutNav data={data} />
                </div>
                <Navside data={data} />
              </div>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem className="border-transparent border-b-[3px] hover:border-seablue ">
          <NavigationMenuLink
            href="/pricing"
            className={navigationMenuTriggerStyle()}
          >
            Pricing
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
