import { Image } from "react-datocms";

export default ({ data }) => {
  return (
    <div class="navmenu__dropdown-block">
      <div class="flex-column-left navmenu__dropdown-right">
        <a
          href={data.sidemenucard[0].url}
          class="mb-12"
          tabindex="0"
          target="_blank"
        >
          <Image
            data={data.sidemenucard[0].image.responsiveImage}
            lazyLoad={true}
            client:visible
          />
        </a>

        <p class="font-bold mb-1">{data.navsidetitle}</p>
        <a
          href={data.sidemenucard[0].url}
          class="text-sm font-bold link-blue"
          target="_blank"
        >
          <strong>See How Your Plant Stacks Up</strong>
        </a>
      </div>
    </div>
  );
};

